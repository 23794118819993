




import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import * as echarts from 'echarts'
import VulnList from '@/views/vuln/components/sync.vue'

@Component({
  name: 'Trend',
})
export default class Index extends VueBase {
  public pngUrl = ''
  @Prop({ default: true }) showAnimation!: boolean
  @Prop() scaData!: any
  @Watch('scaData', { immediate: true, deep: true })
  init() {
    this.obj = {
      高危漏洞: 0,
      中危漏洞: 0,
      低危漏洞: 0,
      提示信息: 0,
    }
    this.total = 0
    this.scaData &&
      this.scaData.forEach((item: any) => {
        this.obj[item.level__name_type] = item.level__name_type__count
        this.total += Number(item.level__name_type__count)
      })
    this.randerEchart()
  }
  obj: any = {
    高危漏洞: 0,
    中危漏洞: 0,
    低危漏洞: 0,
    提示信息: 0,
  }
  total: any = 0

  randerEchart() {
    const option = {
      animation: this.showAnimation,
      legend: {
        show: true,
        data: ['高危', '中危', '低危', '提示'],
        bottom: 0,
      },
      polar: {
        radius: [0, '90%'],
        center: ['50%', '45%'],
      },
      angleAxis: {
        max: this.total,
        show: false,
      },
      radiusAxis: {
        type: 'category',
        show: false,
      },
      tooltip: {
        borderColor: '#fff',
        formatter: (params: any) => {
          return `
            <div style="display: flex;align-items: center;justify-content: space-between;width:160px; ">
                <p style="display: flex;width:100px; align-items: center;position:relative;padding-left:16px">
                <span style="display:inlie-block;width:8px;height:8px;background:${
                  params.color
                }; border-radius:50%;position:absolute;left:0;top:7px;"></span>
                <span style="display:inline-block;white-space:pre-wrap;width:100px;color: #959FB4;">${
                  params.seriesName
                }</span><span style="color: #38435A;margin-right: 8px;">${
            params.value
          }</span></p>
                <p style="color:#959FB4">(${(
                  (params.value / this.total) *
                  100
                ).toFixed(2)}%)</p>
                </div>
                `
        },
      },
      series: [
        {
          layoutAnimation: this.showAnimation,

          name: '提示',
          type: 'bar',
          barGap: 1,
          coordinateSystem: 'polar',
          showBackground: true,
          roundCap: true,
          backgroundStyle: {
            color: 'rgba(242, 243, 245, 1)',
          },
          itemStyle: {
            color: 'rgba(171, 178, 192, 1)',
          },
          data: [this.obj['提示信息']],
        },
        {
          layoutAnimation: this.showAnimation,

          name: '低危',
          type: 'bar',
          coordinateSystem: 'polar',
          showBackground: true,
          roundCap: true,
          backgroundStyle: {
            color: 'rgba(242, 243, 245, 1)',
          },
          itemStyle: {
            color: 'rgba(47, 144, 234, 1)',
          },
          data: [this.obj['低危漏洞']],
        },
        {
          layoutAnimation: this.showAnimation,

          name: '中危',
          type: 'bar',
          coordinateSystem: 'polar',
          showBackground: true,
          roundCap: true,
          backgroundStyle: {
            color: 'rgba(242, 243, 245, 1)',
          },
          itemStyle: {
            color: 'rgba(244, 158, 11, 1)',
          },
          data: [this.obj['中危漏洞']],
        },
        {
          layoutAnimation: this.showAnimation,

          name: '高危',
          type: 'bar',
          coordinateSystem: 'polar',
          showBackground: true,
          roundCap: true,
          backgroundStyle: {
            color: 'rgba(242, 243, 245, 1)',
          },
          itemStyle: {
            color: 'rgba(229, 99, 99, 1)',
          },
          data: [this.obj['高危漏洞']],
        },
      ],
    }
    const dom = (document as any).getElementById('scale')
    if (!dom) {
      return
    }
    const myChart = echarts.init((document as any).getElementById('scale'))
    myChart.setOption(option)
    this.pngUrl = myChart.getDataURL()
  }
}
